<template>
	<div class="view pa24">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
			<el-row>
				<el-col :xl="10">
					<el-form-item label="活动时长" prop="pickerValue">
						<el-date-picker v-model="ruleForm.pickerValue" value-format="yyyy-MM-dd" align="right"
							type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
							:picker-options="pickerOptions" />
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="单账号最多开团数">
						<el-input-number v-model="ruleForm.openRegiment" :min="1" :max="10000" />
					</el-form-item>
				</el-col>
				<el-col :xl="10">
					<el-form-item label="成团人数" prop="peopleNum">
						<el-input-number v-model="ruleForm.peopleNum" :min="2" :max="10000" />
						<!-- <el-input v-model="ruleForm.peopleNum" @change="
                () => {
                  ruleForm.peopleNum =
                    ruleForm.peopleNum < 2
                      ? 2
                      : ruleForm.peopleNum.replace(/\D/g, '');
                }
              " class="w120" placeholder="请输入人数" type="number" /> -->
						拼团人数 > 1
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="单账号最多购买数">
						<el-input-number v-model="ruleForm.maxPurchase" :min="1" :max="10000" />
					</el-form-item>
				</el-col>
				<el-col :xl="10">
					<el-form-item label="拼团有效期(小时)" prop="termOfValidity">
						<el-input v-model="ruleForm.termOfValidity" min="0" max="24" class="w120" placeholder="请输入人数"
							type="number" />
					</el-form-item>
				</el-col>
				<el-col :xl="12">
					<el-form-item label="单账号最多参团数">
						<el-input-number v-model="ruleForm.joinRegiment" :min="1" :max="10000" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div style="color: #ff5c6c">
			注：请填写商品库存，一旦拼团人数和购买数量达到商品库存的值，该拼团则进入关闭状态，用户点击拼团或参团
			则提示“该商品已售罄”。
		</div>
		<div class="mt20" v-if="isDrafts">
			添加拼团商品
			<el-button @click="centerDialogVisible = true" class="ml10" type="primary">+添加商品</el-button>
		</div>
		<commonTable :tableData="tableData" :total="tableData.length" :paginationVisible="false">
			<template v-slot:table>
				<el-table-column prop="goodsId" align="center" label="产品ID" show-overflow-tooltip />
				<el-table-column prop="goodsName" align="center" label="产品" />
				<el-table-column prop="typeName" align="center" label="分类" />
				<!-- <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{
              (scope.row[$route.query.id ? "minPrice" : "price"] / 100).toFixed(
                2
              )
            }}
          </template>
        </el-table-column> -->

				<!--        <el-table-column prop="createTime" align="center" label="发布时间">-->
				<!--          <template slot-scope="scope">-->
				<!--            {{ scope.row.createTime }}-->
				<!--          </template>-->
				<!--        </el-table-column>-->
			</template>
		</commonTable>
		<p class="">选择商品规格</p>
		<commonTable :tableData="specList" :total="specList.length">
			<template v-slot:table>
				<el-table-column prop="title" align="center" label="商品规格" />
				<el-table-column prop="stock" align="center" label="商品库存" />
				<el-table-column prop="price" align="center" label="价格">
					<template slot-scope="scope">
						{{ scope.row.price | formatAmount }}
					</template>
				</el-table-column>

				<el-table-column align="center" label="操作" width="500">
					<template slot-scope="scope">
						团购金额
						<el-input @change="
                () => {
                  scope.row.assemblePrice =
                    scope.row.assemblePrice < 0.01
                      ? 0.01
                      : scope.row.assemblePrice;
                }
              " class="d-inline-block w100" v-model="scope.row.assemblePrice" />
						元 活动库存
						<el-input class="d-inline-block w100" @change="
                () => {
                  scope.row.assembleNum =
                    scope.row.assembleNum < 1
                      ? 1
                      : scope.row.assembleNum.replace(/\D/g, '');
                }
              " v-model="scope.row.assembleNum" />
					</template>
				</el-table-column>
			</template>
		</commonTable>
		<el-dialog
			title="选择商品"
			:visible.sync="centerDialogVisible"
			:modal-append-to-body="false"
			:close-on-click-modal="false"
			:destroy-on-close="true" width="60%" center>
			<product v-if="centerDialogVisible" ref="product" />
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</span>
		</el-dialog>
		<div class="mt20 d-flex justify-content-center" v-if="$route.query.id ? false : true">
			<el-button @click="save(0)" type="primary">保存到草稿箱</el-button>
			<el-button @click="save(1)">发布</el-button>
		</div>
		<div class="mt20 d-flex justify-content-center" v-else>
			<el-button type="primary" @click="save(draftType)">保存编辑</el-button>
		</div>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import product from "@/pages/marketingActivities/spike/components/product";
	import {
		getDataTimeSec
	} from "@/utils";
	import {
		insertGoodsAssemble,
		updateGoodsAssembleInfo
	} from "@/api/joinGroup";
	import {
		get,
		post
	} from "../../../../utils/request";
	import {
		showErrorToast,
		formatAmount
	} from "@/utils/utils";

	export default {
		data() {
			return {
				tableData: [],
				specList: [],
				setSpec: [],
				ruleForm: {
					pickerValue: "",
					createLimit: "",
					peopleNum: "",
					maxPurchase: "",
					termOfValidity: 24,
					joinLimit: "",
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
					},
				},
				rules: {
					pickerValue: [{
						required: true,
						message: "请选择活动时长"
					}],
					peopleNum: [{
						required: true,
						message: "请输入成团人数"
					}],
					termOfValidity: [{
						required: true,
						message: "请输入拼团有效期"
					}],
				},
				centerDialogVisible: false,
				draftType: "",
				isDrafts: "",
				assembleId: "",
			};
		},
		filters: {
			formatAmount(amount) {
				console.log('金额', amount)
				return formatAmount(amount)
			}
		},
		computed: {
			setGetDataTimeSec() {
				return (val) => getDataTimeSec(val);
			},
		},
		components: {
			commonTable,
			product,
		},
		created() {
			this.isDrafts = this.$route.query.drafts == "1" ? false : true;
			const {
				id
			} = this.$route.query;
			if (id) {
				this.ruleForm.assembleId = id;
				this.initDetail();
			}
		},
		methods: {
			peoplenum(val) {
				
			},
			initDetail() {
				get("/assemble/" + this.ruleForm.assembleId).then((res) => {
					res.pickerValue = [res.startTime, res.endTime];
					delete res.startTime;
					delete res.endTime;
					this.ruleForm = res;
					get("/goods/" + res.goodsId).then((res) => {
						this.tableData = [{
							goodsId: res.goodsId,
							goodsName: res.goodsName,
							createTime: res.createTime,
							typeName: res.typeName
						}];
						res.skuList.forEach(item => {
							item.assemblePrice = item.assemblePrice / 100
						})
						this.specList = res.skuList;
					});
				});
			},
			determine() {
				const {
					multipleSelection
				} = this.$refs.product;
				const arr = [];
				this.setSpec = [];
				this.tableData = [multipleSelection];
				get("/goods/" + multipleSelection.goodsId).then((res) => {
					this.specList = res.skuList;
				});
				this.centerDialogVisible = false;
			},
			/**@method  发布*/
			save(type) {
				this.$refs.ruleForm.validate(async (valid) => {
					if (this.tableData.length == 0) {
						this.$message({
							message: "请先选择商品",
							type: "warning",
						});
						return;
					}
					var hasError = false;
					this.specList.forEach((item) => {
						if (!item.assemblePrice) {
							hasError = true;
							showErrorToast(this, "团购金额不能为空");
							return;
						}
						if (!item.assembleNum) {
							hasError = true;
							showErrorToast(this, "团购数量不能为空");
							return;
						}
						if (item.assemblePrice > item.price) {
							hasError = true;
							showErrorToast(this, "团购金额不能大于商品金额");
							return;
						}
						if (item.assembleNum > item.stock) {
							hasError = true;
							showErrorToast(this, "团购库存不能大于商品库存");
							return;
						}
					});
					if (hasError) {
						return;
					}
					var params = {};
					Object.assign(params, this.ruleForm);
					params.goodsId = this.tableData[0].goodsId;
					delete params.pickerValue;
					params.startTime = this.ruleForm.pickerValue[0] + " 00:00:00";
					params.endTime = this.ruleForm.pickerValue[1] + " 23:59:59";
					params.assembleType = type;
					var skuList = [];
					this.specList.forEach((item) => {
						skuList.push({
							id: item.id,
							assemblePrice: item.assemblePrice * 100,
							assembleNum: item.assembleNum,
						});
					});
					params.skuList = skuList;
					post("/assemble", params).then((res) => {
						this.$router.go(-1);
					});
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>
